<template>
  <v-col cols="12" class="m-about-us">
    <v-img  src="../../assets/mobile/aboutus/bg.png"></v-img>
  </v-col>
</template>

<script>
export default {

}
</script>

<style>
.m-about-us {
     padding:0px;
}

</style>