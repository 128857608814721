<template>
  <v-container fluid>
    <span class="bottom-right">
      <v-img src="../assets/home/bottomRight.svg"></v-img>
    </span>
    <v-row>
      <v-col cols="12" class="nav-bar-home">
        <span class="nav-logo">
          <v-img src="../assets/logo.svg"></v-img>
        </span>
        <span class="nav-item" @click="this.$store.commit('setCurrentTab','home')" :class="getCurrentTab == 'home' ? 'selected':''">Home</span>
        <span class="nav-item" @click="this.$store.commit('setCurrentTab','services')" :class="getCurrentTab == 'services' ? 'selected':''">Services</span>
        <span class="nav-item" @click="this.$store.commit('setCurrentTab','plateforms')" :class="getCurrentTab == 'plateforms' ? 'selected':''">Plateforms</span>
        <span class="nav-item" @click="this.$store.commit('setCurrentTab','aboutus')" :class="getCurrentTab == 'aboutus' ? 'selected':''">About Us</span>
        <span class="nav-item" @click="this.$store.commit('setCurrentTab','sportid')" :class="getCurrentTab == 'sportid' ? 'selected':''">Sports ID</span>
        <span class="nav-item" @click="this.$store.commit('setCurrentTab','contactus')" :class="getCurrentTab == 'contactus' ? 'selected':''">Contact Us</span>
        <span class="nav-lang">
          <div class="nav-lang-icon">
            <v-img src="../assets/home/lang.svg"></v-img>
          </div>
          <div>
            <select class="nav-select">
              <option class="options">English</option>
            </select>
          </div>
        </span>
      </v-col>
    </v-row>
    <v-row class="home" justify="center">
      <v-col lg="4" md="5" sm="5" class="h-col-2">
        <v-img src="../assets/home/head.svg" />
      </v-col>
    </v-row>

    <v-row class="home3" justify="center">
      <v-col lg="4" md="5" sm="6" class="h3-col-1">
        <v-img src="../assets/home/computer.svg"></v-img>
      </v-col>
      <v-col lg="4" md="5" sm="6" class="h3-col-2">
        <v-img src="../assets/home/home3head.svg"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: "Home",
  data: () => ({
    loading: true,
  }),
  mounted() {
    let that = this;
    setTimeout(function () {
      that.setLoader(false);
    }, 2000);
  },
  computed: {
    ...mapGetters(['getCurrentTab'])
  },
  methods: {
    setLoader(val) {
      try {
        this.loading = val;
      } catch (error) { }
    },
  },
};
</script>
<style scoped>
.selected {
  color: #EFAD19;
  height: 100%;
  border-bottom: 5px solid #EFAD19;
  align-content: center;
}

.bottom-right {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 200px;
}

.nav-select {
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  background-color: transparent;
  padding: 10px 20px;
  border: none;
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../assets/home/arrow.svg") right no-repeat;
}

.nav-select:focus {
  border: none;
  outline: none;
}

.nav-lang {
  display: flex;
  align-content: center;
}

.nav-lang-icon {
  width: 34px;
  height: 34px;
}

.nav-logo {
  width: 238px;
}

.nav-bar-home {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.nav-item {
  font-size: 17px;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.home {
  background-image: url("../assets/home/background.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 1236px;
  box-shadow: 0px -85px 40px #000 inset;
}

.h-col-1 {
  min-height: 790px;
  display: flex;
  align-items: self-end;
}

.h-col-2 {
  align-content: center;
  place-self: start;
  padding-top: 230px;
}

.home2 {
  background: url("../assets/home/home2bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  margin-top: -250px;
}

.h2-col-1 {
  height: 660px;
}

.h3-col-2 {
  align-self: center;
}

.home3 {
  max-height: 338px;
}

@media only screen and (max-width: 768px) {
  .home {
    height: 585px;
  }

  .h-col-2 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 1024px) {
  .home {
    height: 785px;
  }

  .h-col-2 {
    padding-top: 115px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .home {
    height: 1100px;
  }

  .h-col-2 {
    padding-top: 180px;
  }
}
</style>
>
