<template>
  <v-responsive>
    <v-app>
      <install-vue></install-vue>
      <v-main v-if="this.$vuetify.display.width < 769">
      <mobile></mobile>
      </v-main>
      <v-main v-else>
        <Home />
        <v-container fluid>
          <AboutUsVue />
        </v-container>
        <PartnerVue />
        <SportIdVue />

        <ServicesVue />
        <v-container fluid>
          <ContactUsVue />
        </v-container>
      </v-main>
    </v-app>
  </v-responsive>
</template>

<script>
import AboutUsVue from "./components/AboutUs.vue";
import ContactUsVue from "./components/ContactUs.vue";
import Home from "./components/Home.vue";
import PartnerVue from "./components/Partner.vue";
import ServicesVue from "./components/Services.vue";
import SportIdVue from "./components/SportId.vue";
import Mobile from "./components/Mobile.vue";
import installVue from './components/install.vue';

export default {
  name: "App",

  components: {
    Home,
    AboutUsVue,
    SportIdVue,
    ServicesVue,
    ContactUsVue,
    PartnerVue,
    Mobile,
    installVue
  },
  mounted() {
    console.log(this.$vuetify.display.width)
  },
};
</script>
