<template>
  <v-container class="partner" fluid>
    <v-row>
      <v-col class="p-head"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="p-col-1"> </v-col>
      <v-col cols="3" class="p-col-2"> </v-col>
      <v-col cols="3" class="p-col-3"> </v-col>
      <v-col cols="3" class="p-col-4"> </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Home",
  data: () => ({
    loading: true,
  }),
  mounted() {
    let that = this;
    setTimeout(function () {
      that.setLoader(false);
    }, 2000);
  },
  methods: {
    setLoader(val) {
      try {
        this.loading = val;
      } catch (error) {}
    },
  },
};
</script>
<style scoped>
.partner {
  background: url("../assets/partner/bg.svg");
  min-height: 827px;
  align-content: space-evenly;
}

.p-col-1 {
  background: url("../assets//partner/card1.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 330px;
  background-origin: content-box;
}

.p-col-2 {
  background: url("../assets//partner/card2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 330px;
  background-origin: content-box;
}

.p-col-3 {
  background: url("../assets//partner/card3.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 330px;
  background-origin: content-box;
}

.p-col-4 {
  background: url("../assets//partner/card4.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 330px;
  background-origin: content-box;
}

.p-head {
  background: url("../assets/partner/head.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-height: 130px;
}
</style>
