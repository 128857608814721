<template>
  <v-col cols="12" class="m-home">
    <v-img src="../../assets/mobile/home/home.png"></v-img>
  </v-col>
</template>

<script>
export default {

}
</script>

<style>
.m-home{
    padding:0px;
}
</style>