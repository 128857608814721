<template>
  <v-row class="m-header m-0">
    <v-col cols="3"  >
        <v-img  class="m-humburger" src="../assets/mobile/hamburger.svg"></v-img>
    </v-col>
    <v-col cols="6" >
        <v-img  class="m-logo" src="../assets/logo.svg"></v-img>
    </v-col>
    <v-col cols="3" >
        <span>
            <v-img class="m-lang" src="../assets/home/lang.svg"></v-img>
        </span>
        <span></span>
    </v-col>
  </v-row>
  <v-row class="m-main-page">
    <home></home>
    <about-us></about-us>
   <our-partner></our-partner>
   <how-it-work></how-it-work>
   <why-tata-book></why-tata-book>
   <get-in-touch></get-in-touch>
  </v-row>
</template>

<script>
import AboutUs from './mobile/AboutUs.vue'
import GetInTouch from './mobile/GetInTouch.vue'
import Home from './mobile/Home.vue'
import HowItWork from './mobile/HowItWork.vue'
import OurPartner from './mobile/OurPartner.vue'
import WhyTataBook from './mobile/WhyTataBook.vue'
export default {
  components: { OurPartner, AboutUs, Home, HowItWork, WhyTataBook, GetInTouch },

}
</script>

<style>
.m-header {
    margin: 0px;
}
.m-header .v-col {
     height:74px;
     margin:0px;
     padding:0px;
     align-content: center;
}

.m-header .v-col:nth-child(3),  .m-header .v-col:nth-child(1){
    text-align: -webkit-center;
}
.m-logo {
    height:68px;
}
.m-humburger {
    height: 36px;
}
.m-lang {
    width:28px;
    height:28px;
}
.m-home {
    height:100%;
}
.m-main-page {
    margin-top:0px !important;
}
</style>