<template>
  <v-row justify="center" class="contact-us">
    <v-col lg="3" md="3" sm="3" class="mx-5 contact-div">
      <div>
        <v-img src="../assets/contactus/Getintouch.svg"></v-img>
      </div>
      <div class="social-media">
        <v-img class="sm-child" src="../assets/contactus/Link.svg"></v-img>
        <v-img class="sm-child" src="../assets/contactus/Link.svg"></v-img>
        <v-img class="sm-child" src="../assets/contactus/Link.svg"></v-img>
        <v-img class="sm-child" src="../assets/contactus/Link.svg"></v-img>
        <span class="sm-child"></span>
        <span class="sm-child"></span>
      </div>
    </v-col>
    <v-col
      lg="3"
      md="3"
      sm="3"
      class="mx-5 contact-div contact-bg c-col-2"
    ></v-col>
    <v-col
      lg="3"
      md="3"
      sm="3"
      class="mx-5 contact-div contact-bg c-col-3"
    ></v-col>
  </v-row>
  <v-row>
    <v-col cols="12" class="nav-bar-home">
      <span class="nav-logo">
        <v-img src="../assets/logo.svg"></v-img>
      </span>
      <span class="nav-item" @click="this.$store.commit('setCurrentTab','home')" :class="getCurrentTab == 'home' ? 'selected':''">Home</span>
      <span class="nav-item" @click="this.$store.commit('setCurrentTab','services')" :class="getCurrentTab == 'services' ? 'selected':''">Services</span>
      <span class="nav-item" @click="this.$store.commit('setCurrentTab','plateforms')" :class="getCurrentTab == 'plateforms' ? 'selected':''">Plateforms</span>
      <span class="nav-item" @click="this.$store.commit('setCurrentTab','aboutus')" :class="getCurrentTab == 'aboutus' ? 'selected':''">About Us</span>
      <span class="nav-item" @click="this.$store.commit('setCurrentTab','sportid')" :class="getCurrentTab == 'sportid' ? 'selected':''">Sports ID</span>
      <span class="nav-item" @click="this.$store.commit('setCurrentTab','contactus')" :class="getCurrentTab == 'contactus' ? 'selected':''">Contact Us</span>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "Home",
  data: () => ({
    loading: true,
  }),
  mounted() {
    let that = this;
    setTimeout(function () {
      that.setLoader(false);
    }, 2000);
  },
  computed:{
    ...mapGetters(['getCurrentTab'])
  },
  methods: {
    setLoader(val) {
      try {
        this.loading = val;
      } catch (error) {}
    },
  },
};
</script>
<style scoped>
.selected {
  color: #EFAD19;
  height: 100%;
  border-bottom: 5px solid #EFAD19;
  align-content: center;
}
.nav-select {
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  background-color: transparent;
  padding: 10px 20px;
  border: none;
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../assets/home/arrow.svg") right no-repeat;
}
.nav-select:focus {
  border: none;
  outline: none;
}
option:hover {
  background-color: yellow;
}
.nav-lang {
  display: flex;
  align-content: center;
}
.nav-lang-icon {
  width: 34px;
  height: 34px;
}
.nav-logo {
  width: 238px;
}
.nav-bar-home {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 200px;
}

.nav-item {
  font-size: 17px;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.contact-us {
  background: url("../assets/contactus/bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 685px;
  align-content: center;
}

.contact-div {
  height: 301px;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact-bg {
  background: #3c3c3e;
}

.text-design-w {
  color: #fff;
  -webkit-text-stroke: 1px #f5ba37;
  text-shadow: -1px -1px 0 #f5ba37, 1px -1px 0 #f5ba37, -1px 1px 0 #f5ba37,
    1px 1px 0 #f20808;
}

.text-design-y {
  color: #feef85;
  -webkit-text-stroke: 1px #f5ba37;
  text-shadow: -1px -1px 0 #f5ba37, 1px -1px 0 #f5ba37, -1px 1px 0 #f5ba37,
    1px 1px 0 #f5ba37;
}

.social-media {
  display: flex;
}

.sm-child {
  width: 35px;
  height: 35px;
}

.c-col-2 {
  background: url("../assets/contactus/whatapp.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.c-col-3 {
  background: url("../assets/contactus/steptostep.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 768px) {
  .contact-div {
    height: 160px;
  }
  .contact-us {
    height: 300px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .contact-div {
    height: 210px;
  }
  .contact-us {
    height: 400px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .contact-div {
    height: 210px;
  }
  .contact-us {
    height: 620px;
  }
}
</style>
