<template>
  <v-col cols="12" class="m-ourpartner" >
    <v-img src="../../assets/mobile/ourpartner/bg.png"></v-img>
        <!-- <div class="card-container"> -->
        <!-- Card 1 -->
        <!-- <div class="card" style="background-color: #b83d0a;">
            <div class="card-content">
                <v-img src="../../assets/mobile/ourpartner/image32-4.png" alt="99EXCH Logo" class="logo"></v-img>
                <h2>99 EXCH</h2>
                <button class="play-btn plb-1">Play Now</button>
            </div>
        </div> -->
        <!-- Card 2 -->
        <!-- <div class="card" style="background-color: #b37a1d;">
            <div class="card-content">
                <v-img src="../../assets/mobile/ourpartner/image32.png" alt="11XPLAY Logo" class="logo"></v-img>
                <h2>11XPLAY</h2>
                <button class="play-btn plb-2">Play Now</button>
            </div>
        </div> -->
        <!-- Card 3 -->
        <!-- <div class="card" style="background-color: #24324e;">
            <div class="card-content">
                <v-img src="../../assets/mobile/ourpartner/image32-2.png" alt="LCPLAY247 Logo" class="logo"></v-img>
                <h2>LCPLAY247</h2>
                <button class="play-btn plb-3">Play Now</button>
            </div>
        </div> -->
        <!-- Card 4 -->
        <!-- <div class="card" style="background-color: #562f5b;">
            <div class="card-content">
                <v-img src="../../assets/mobile/ourpartner/image32-3.png" alt="LASER Logo" class="logo"></v-img>
                <h2>LASER</h2>
                <button class="play-btn plb-4">Play Now</button>
            </div>
        </div> -->
    <!-- </div> -->
    </v-col>
</template>

<script>
export default {

}
</script>

<style>
.m-ourpartner {
    margin-top: 0px !important;
}

/* Container for Cards */
.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 20px;
    background-color: #111; /* Adjust as needed */
}

/* Card Styling */
.card {
    width: -webkit-fill-available;
    height: 332px;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    color: #fff;
    position: relative;
    margin-bottom:70px;
}
.card:nth-child(1){
    background: url('../../assets/mobile/ourpartner/a.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.card:nth-child(2){
    background: url('../../assets/mobile/ourpartner/a-2.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.card:nth-child(3){
    background: url('../../assets/mobile/ourpartner/a-3.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.card:nth-child(4){
    background: url('../../assets/mobile/ourpartner/4-2.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

/* Logo Styling */
.logo {
    width: 168px; /* Adjust width */
    height:66px;
    max-height:66px;
}

/* Play Button Styling */
.play-btn {
    cursor: pointer;
    width: 224px;
    height: 60px;
    margin-bottom: -100px;
}
.plb-1 {
   background:url(../../assets/mobile/ourpartner/Rectangle2.png);
   background-repeat: no-repeat;
   background-size: cover;
}
.plb-2 {
   background:url(../../assets/mobile/ourpartner/ButtonClip.png);
   background-repeat: no-repeat;
   background-size: cover;
    
}
.plb-3 {
   background:url(../../assets/mobile/ourpartner/Rectangle2-2.png);
   background-repeat: no-repeat;
   background-size: cover;
    
}
.plb-4 {
   background:url(../../assets/mobile/ourpartner/Rectangle2-3.png);
   background-repeat: no-repeat;
   background-size: cover;
    
}
.play-btn:hover {
    background-color: #ff9800;
    color: #000;
}
</style>