<template>
    <v-col cols="12" class="m-get-in-touch">
      <v-img  src="../../assets/mobile/getintouch/bg.png"></v-img>
    </v-col>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  .m-get-in-touch {
       padding:0px;
  }
  
  </style>