<template>
    <div id="installPrompt" v-if="isPopupVisible">
        <div>
            Install our app for a better experience!
        </div>
        <div class="div2">
            <button id="cancelButton" @click="isPopupVisible = false">Cancel</button>
            <button id="installButton" @click="getItInstalled()">Install</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            deferredPrompt:'',
            isPopupVisible: false
        }
    },
    created() {
        let that = this 
        // Listen for the beforeinstallprompt event
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent the default mini-infobar from appearing
            e.preventDefault();

            // Stash the event so it can be triggered later.
            that.deferredPrompt = e;

            // Show your custom install prompt UI
            that.isPopupVisible = true
        });

        // Optionally, listen for the appinstalled event
        window.addEventListener('appinstalled', () => {
            console.log('PWA was installed');
            that.appInstalled();
        });

    },
    methods: {
        async getItInstalled() {
            try {
                // Hide the custom install prompt UI
               this.isPopupVisible = false

                // Show the browser's install prompt
                if (this.deferredPrompt) {
                    this.deferredPrompt.prompt();

                    // Wait for the user to respond to the prompt
                    const { outcome } = await this.deferredPrompt.userChoice;
                    console.log(`User response to the install prompt: ${outcome}`);

                    // Clear the deferredPrompt variable
                    this.deferredPrompt = null;
                }
            } catch (error) {

            }
        },
        appInstalled() {
            try {

            } catch (error) {

            }
        }
    },
}
</script>

<style>
#installPrompt {
    position: fixed;
    bottom: 20px;
    left: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    font-size: 17px;
    font-weight: 400;
}
#installPrompt button {
    background-color: #EFAD19;
    color:#fff;
    padding: 5px 30px;
    width: fit-content;
    align-self: center;
    margin-top: 10px;
    border-radius: 20px;
}
.div2 {
    display: flex;
    justify-content: space-between;
}
</style>