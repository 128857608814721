<template>
  <v-row class="about-us" justify="center">
    <v-col sm="8" md="8" lg="8" class="a-col-1">
      <v-img src="../assets/aboutus/heading.svg" class="img-head"></v-img>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Home",
  data: () => ({
    loading: true,
  }),
  mounted() {
    let that = this;
    setTimeout(function () {
      that.setLoader(false);
    }, 2000);
  },
  methods: {
    setLoader(val) {
      try {
        this.loading = val;
      } catch (error) {}
    },
  },
};
</script>
<style scoped>
.about-us {
  background: url("../assets/aboutus/bg.svg");
  height: 735px;
  background-size: contain;
  background-repeat: no-repeat;
}
.a-col-1 {
  height: 465px;
  align-self: center;
}
</style>
