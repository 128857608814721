<template>
    <v-col cols="12" class="m-how-it-work">
      <v-img  src="../../assets/mobile/howitwork/bg.png"></v-img>
    </v-col>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  .m-how-it-work {
       padding:0px;
  }
  
  </style>