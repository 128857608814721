<template>
    <v-col cols="12" class="m-why-tata-book">
      <v-img  src="../../assets/mobile/why/bg.png"></v-img>
    </v-col>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  .m-why-tata-book {
    padding: 40px;
    margin-left: 4px;
  }
  
  </style>