import { createStore } from "vuex";

export default createStore({
  state: {
    currentTab:'home'
  },
  getters: {
    getCurrentTab (state)  {
      return state.currentTab
    }
  },
  mutations: {
    setCurrentTab (state, data) {
      state.currentTab = data
    }
  },
  actions: {},
  modules: {},
});
